import { Component, OnInit, Inject, OnDestroy, ChangeDetectorRef, AfterViewInit, AfterViewChecked } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Subject, interval, map, takeUntil } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService } from './service/state.service';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy, AfterViewChecked, AfterViewInit {

  change: boolean = false;
  unsubscribe$ = new Subject();

  constructor(private router: Router,
    private toastr: ToastrService,
    // @Inject(DOCUMENT) private _document: HTMLDocument,
    @Inject(DOCUMENT) private _document: Document,
    private modalService: NgbModal,
    private stateService: StateService,
    private metaService: Meta,
    private titleService: Title,
    private cdRef: ChangeDetectorRef,) { }

  ngOnDestroy(): void {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  ngAfterViewInit(): void {
    this.cdRef.detach();
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    this.addMetaTag();
    const checkInternetSpeed$ = interval(1000)
      .pipe(
        map(() => (navigator as any).connection?.effectiveType),
      );

    checkInternetSpeed$.subscribe(speed => {
      if (speed == '4g') this.change = false;

      if ((speed == '3g' || speed == '2g') && !this.change) {
        this.change = true;;
        this.toastr.info("Your internet speed is slow.")
      }
    });

    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);

    window.addEventListener('popstate', () => {
      if (this.modalService.hasOpenModals()) {
        this.modalService.dismissAll();
      }
    });

    // if (navigator.platform.indexOf('Mac') > -1) {
    if (navigator.userAgent.includes('Mac')) {

      this._document.getElementById('appFavicon')?.setAttribute("href", "../assets/favicon_32x32.ico");
    } else {
      this._document.getElementById('appFavicon')?.setAttribute("href", "../assets/favicon_20x20.ico");
    }


    this.router.events.subscribe(x => {
      if (x instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
  }

  updateOnlineStatus = () => {
    if (!navigator.onLine) {
      this.toastr.error("Please check your internet.")
    } else {
      this.toastr.success("You are back online.")
    }
  }

  addMetaTag() {
    this.metaService.addTag({ name: 'description', content: 'Gangotri Camphor is one of the fastest-growing Camphor Manufacturers in India. We Provide Highest-Quality and 100% Pure Camphor.' });
    this.titleService.setTitle('Gangotri Divine Camphor');
    let isProdEnv = environment.production
    if (!isProdEnv) {
      this.metaService.addTag({ name: 'robots', content: 'noindex, nofollow' });
    }
  }
}