export const environment = {
  production: false,
  title: 'Production Environment',
  apiURL: 'https://demo.cfcsitservices.co.in',
  b2bApiUrl: 'https://demo.cfcsitservices.co.in/api/v1/B2B/admin',
  b2bApiUrl2: 'https://demo.cfcsitservices.co.in/api/v1/B2B',
  salesApiURL: 'https://demo.cfcsitservices.co.in/api/v1/Sales/Admin',
  imageUrl: 'https://demo.cfcsitservices.co.in/images',
  blogImageUrl: 'https://demo.cfcsitservices.co.in/blog',
  bannerImageUrl: 'https://demo.cfcsitservices.co.in/banner',
  thumbNailUrl: 'https://demo.cfcsitservices.co.in/thumbnail',
};
